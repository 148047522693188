import React from "react";
import { Dialog } from "../../../../src/components/Dialog";
import { t } from "../../../../src/i18n";

import { appJotaiStore } from "../../../app-jotai";
import { FilledButtonLink } from "../../../../src/classuper/components/FilledButton";
import { isRedirectToClassuperAtom } from "./constants";

import "./RedirectToClussuperModal.scss";

export const RedirectToClassuperModal = () => {
  const getTitle = (): string => t("redirectToClassuperDialog.title");

  const handleClose = () => {
    appJotaiStore.set(isRedirectToClassuperAtom, false);
  };

  return (
    <Dialog size="small" onCloseRequest={handleClose} title={getTitle()}>
      <div className="Card-details">{t("redirectToClassuperDialog.desc")}</div>
      <div className="buttonWrapper">
        <FilledButtonLink
          className="Card-button"
          size="medium"
          label={t("redirectToClassuperDialog.goto")}
          url="https://app.classuper.ru/signup"
        />
      </div>
      <div className="Card-subTitle">
        {t("redirectToClassuperDialog.description")}
      </div>
    </Dialog>
  );
};
