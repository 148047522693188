import { boardEvents } from "../classuperConstants";

export const loadLibraryFromStorage = () => {
  if (window.top) {
    window.top.postMessage(
      { type: boardEvents.LIBRARY_LOAD, name: "Библиотека Доски" },
      "*",
    );
  }

  return [];
};
