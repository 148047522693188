import { boardEvents } from "../../../src/classuperConstants";

export function getUserInfo() {
  if (window.top) {
    window.top.postMessage(
      {
        type: boardEvents.GET_USER_INFO,
      },
      "*",
    );
  }
}
