import { boardEvents } from "../classuperConstants";
import { LibraryItems } from "../types";
import { inIframe } from "./inIframe";

export const saveLibraryToStorage = (items: LibraryItems) => {
  if (inIframe() && window.top) {
    const serializedItems = JSON.stringify(items);

    window.top.postMessage(
      {
        type: boardEvents.LIBRARY_SAVE,
        items: serializedItems,
        name: "Библиотека Доски.classuperBoardLib",
      },
      "*",
    );
  }
};
