import { v4 as uuidv4 } from 'uuid';
import {STORAGE_KEYS} from "../../app_constants";

export const getUniqueId = (): string => {
    let uniqueId = localStorage.getItem(STORAGE_KEYS.WSS_UNIQUE_ID);
    if(!uniqueId){
        uniqueId = uuidv4();
        localStorage.setItem(STORAGE_KEYS.WSS_UNIQUE_ID, uniqueId)
    }
    return uniqueId;
}