import { inIframe } from "../../src/utils/inIframe";

import { boardEvents } from "../../src/classuperConstants";
import { sendQueryLoadSceneFromStorage } from "../../src/utils/sendQueryLoadSceneFomStorage";

export function getPreparedClassuperDocument() {
  if (inIframe()) {
    sendQueryLoadSceneFromStorage(boardEvents.LOAD_DOCUMENT_AFTER_START_BOARD);
  }
  return false;
}
