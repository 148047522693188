import React from "react";
import { MainMenu } from "../../src/packages/excalidraw/index";
import { LanguageList } from "./LanguageList";
import { showRedirectToClassuperModal } from "../classuper/components/redirectToClassuperModal/constants";
import {
  USER_ROLES,
  userRoleAtom,
} from "../../src/utils/temporarySceneStorage";
import { inIframe } from "../../src/utils/inIframe";
import { useAtom } from "jotai";

export const AppMainMenu: React.FC<{
  setCollabDialogShown: (toggle: boolean) => any;
  isCollaborating: boolean;
  isCollabEnabled: boolean;
  isAnswerDocument: boolean;
}> = React.memo((props) => {
  const [userRole] = useAtom(userRoleAtom);

  const isShow = !inIframe() || userRole === USER_ROLES.teacher;

  return (
    <MainMenu>
      {isShow && <MainMenu.DefaultItems.LoadScene />}
      {isShow && <MainMenu.ClassuperItems.LoadSceneFromStorage />}
      {isShow && <MainMenu.DefaultItems.SaveToActiveFile />}
      <MainMenu.ClassuperItems.SaveAsAnswerCommentImage />
      {isShow && <MainMenu.DefaultItems.Export />}
      {isShow && <MainMenu.DefaultItems.SaveAsImage />}
      {props.isCollabEnabled && (
        <MainMenu.DefaultItems.LiveCollaborationTrigger
          isCollaborating={props.isCollaborating}
          onSelect={() =>
            showRedirectToClassuperModal() || props.setCollabDialogShown(true)
          }
        />
      )}

      <MainMenu.DefaultItems.Help />
      <MainMenu.DefaultItems.ClearCanvas />
      <MainMenu.Separator />
      <MainMenu.DefaultItems.Socials />
      <MainMenu.Separator />
      <MainMenu.DefaultItems.ToggleTheme />
      <MainMenu.ItemCustom>
        <LanguageList style={{ width: "100%" }} />
      </MainMenu.ItemCustom>
      <MainMenu.DefaultItems.ChangeCanvasBackground />
    </MainMenu>
  );
});
