import React from "react";

export const ClassuperDashboardAppLink = () => {
  return (
    <a
      href="https://classuper.ru"
      target="_blank"
      rel="noreferrer"
      className="link-button"
    >
      <img src="logo.png" alt="Классапер. Главная" height={28} />
    </a>
  );
};
