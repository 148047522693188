import { MIME_TYPES } from "../constants";
import { boardEvents } from "../classuperConstants";
import { ExcalidrawElement } from "../element/types";
import { AppState, BinaryFiles } from "../types";
import { serializeAsJSON } from "./json";
import { getNonDeletedElements } from "../element";
import { getSceneAsImage } from "../utils/getSceneAsImage";
import { TemporarySceneParams } from "../utils/temporarySceneStorage";

export const saveAsJSONToStorage = async (
  elements: readonly ExcalidrawElement[],
  appState: AppState,
  files: BinaryFiles,
) => {
  const serialized = serializeAsJSON(elements, appState, files, "local");
  const blob = new Blob([serialized], {
    type: MIME_TYPES.excalidraw,
  });

  if (window.top) {
    window.top.postMessage(
      {
        type: boardEvents.SCENE_SAVE,
        blob,
        name: `${appState.name}.classuper`,
      },
      "*",
    );
  }
};

export const saveAsImageToStorage = async (
  elements: readonly ExcalidrawElement[],
  appState: AppState,
  files: BinaryFiles,
) => {
  const nonDeletedElements = getNonDeletedElements(elements);
  const blob = await getSceneAsImage(nonDeletedElements, appState, files);

  if (window.top) {
    window.top.postMessage(
      { type: boardEvents.SCENE_SAVE, blob, name: `${appState.name}.png` },
      "*",
    );
  }
};

export const saveAsImageToComment = async (
  elements: readonly ExcalidrawElement[],
  appState: AppState,
  files: BinaryFiles,
  params: TemporarySceneParams | undefined,
) => {
  const nonDeletedElements = getNonDeletedElements(elements);
  const blob = await getSceneAsImage(nonDeletedElements, appState, files);

  if (window.top) {
    window.top.postMessage(
      {
        type: boardEvents.IMAGE_SAVE_TO_ANSWER,
        blob,
        name: `${appState.name}.png`,
        params,
      },
      "*",
    );
  }
};
