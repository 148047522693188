import { useEffect, useRef, useState } from "react";
import "./EndFreeTimeModal.scss";
import { Dialog } from "../../../../src/components/Dialog";
import { t } from "../../../../src/i18n";
import { END_FREE_TIME_SHOW_MODAL, FREE_TIME } from "../../constants";
import { appJotaiStore } from "../../../app-jotai";
import { collabTimer, isEndFreeTimeAtom } from "../../collabTimer";
import { temporarySceneStorage } from "../../../../src/utils/temporarySceneStorage";
import { FilledButtonLink } from "../../../../src/classuper/components/FilledButton";
import { fillStringAsTemplate } from "../../utils/fillStringAsTemplate";

export const EndFreeTimeModal = () => {
  const [timer, setTimer] = useState(END_FREE_TIME_SHOW_MODAL);
  const timerIdRef: { current: NodeJS.Timeout | null } = useRef(null);

  const counter = () => {
    setTimer((timer: number) => {
      const newTime = timer - 1;
      if (!newTime) {
        timerClear();
        appJotaiStore.set(isEndFreeTimeAtom, false);
        collabTimer.reStart();
      }
      return newTime;
    });
  };

  useEffect(() => {
    if (timerIdRef.current) {
      timerClear();
    }

    timerIdRef.current = setInterval(counter, 1000);

    return () => {
      timerClear();
    };
  }, []);

  const timerClear = () => {
    clearInterval(timerIdRef.current as NodeJS.Timeout);
  };

  const isTeacher = () => temporarySceneStorage.isTeacherRole();

  const getLabel = (): string => {
    const x = isTeacher()
      ? "endFreeTimeDialog.label_teacher"
      : "endFreeTimeDialog.label_student";
    return t(x);
  };

  const getDesc = (): string => {
    const x = isTeacher()
      ? "endFreeTimeDialog.desc_teacher"
      : "endFreeTimeDialog.desc_student";

    return fillStringAsTemplate(t(x), {
      FREE_TIME,
      END_FREE_TIME_SHOW_MODAL,
    });
  };

  return (
    <Dialog
      size="small"
      onCloseRequest={() => {}}
      hideCloseBtn={true}
      title={false}
    >
      <div className="EndFreeTimeModal">
        <h3 className="EndFreeTimeModal__active__header">{getLabel()}</h3>
        <div className="EndFreeTimeModal__active__description">
          <p>{getDesc()}</p>
          <div className="EndFreeTimeModal__counter">{timer}</div>
          {isTeacher() && (
            <div className={"EndFreeTimeModal__active__actions"}>
              <FilledButtonLink
                size="medium"
                label={t("endFreeTimeDialog.open_tariff")}
                url="https://classuper.ru/upgrade"
              />
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};
