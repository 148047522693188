import { saveAs } from "../components/icons";
import { ToolButton } from "../components/ToolButton";
import { t } from "../i18n";
import { useDevice } from "../components/App";
import { KEYS } from "../keys";
import { register } from "./register";
import { nativeFileSystemSupported } from "../data/filesystem";
import {
  saveAsImageToComment,
  saveAsImageToStorage,
  saveAsJSONToStorage,
} from "../data/clussuperJson";

import "../components/ToolIcon.scss";
import { sendQueryLoadSceneFromStorage } from "../utils/sendQueryLoadSceneFomStorage";
import { boardEvents } from "../classuperConstants";
import {
  TemporaryScene,
  temporarySceneStorage,
  TemporarySceneStorage,
} from "../utils/temporarySceneStorage";

export const actionSaveFileToStorage = register({
  name: "saveSceneToStorage",
  viewMode: true,
  trackEvent: { category: "export" },
  perform: async (elements, appState, value, app) => {
    try {
      await saveAsJSONToStorage(
        elements,
        {
          ...appState,
          fileHandle: null,
        },
        app.files,
      );

      return false;
    } catch (error: any) {
      if (error?.name !== "AbortError") {
        console.error(error);
      } else {
        console.warn(error);
      }
      return { commitToHistory: false };
    }
  },
  keyTest: (event) =>
    event.key === KEYS.S && event.shiftKey && event[KEYS.CTRL_OR_CMD],
  PanelComponent: ({ updateData }) => (
    <ToolButton
      type="button"
      icon={saveAs}
      title={t("buttons.save")}
      aria-label={t("buttons.save")}
      showAriaLabel={useDevice().isMobile}
      hidden={!nativeFileSystemSupported}
      onClick={() => updateData(null)}
      data-testid="save-as-button"
    />
  ),
});

export const actionLoadSceneFromStorage = register({
  name: "loadSceneFromStorage",
  trackEvent: { category: "export" },
  predicate: (elements, appState, props, app) => {
    return (
      !!app.props.UIOptions.canvasActions.loadScene && !appState.viewModeEnabled
    );
  },
  perform: async (elements, appState, _, app) => {
    try {
      sendQueryLoadSceneFromStorage(boardEvents.SCENE_LOAD);
      return false;
    } catch (error: any) {
      if (true || error?.name === "AbortError") {
        console.warn(error);
        return false;
      }
      return {
        elements,
        appState: { ...appState, errorMessage: error.message },
        files: app.files,
        commitToHistory: false,
      };
    }
  },
  keyTest: (event) => event[KEYS.CTRL_OR_CMD] && event.key === KEYS.O,
});

export const actionSetLoadedStorageScene = register({
  name: "setLoadedStorageScene",
  trackEvent: { category: "export" },
  predicate: (elements, appState, props, app) => {
    return (
      !!app.props.UIOptions.canvasActions.loadScene && !appState.viewModeEnabled
    );
  },
  perform: async (elements, appState, _, app) => {
    try {
      const tempSceneStorage = new TemporarySceneStorage();

      const {
        appState: loadedAppState,
        elements: loadedElements,
        files,
      }: TemporaryScene = tempSceneStorage.getScene();

      return {
        appState: loadedAppState,
        elements: loadedElements,
        files,
        commitToHistory: true,
      };
    } catch (error: any) {
      if (error?.name === "AbortError") {
        console.warn(error);
        return false;
      }
      return {
        elements,
        appState: { ...appState, errorMessage: error.message },
        files: app.files,
        commitToHistory: false,
      };
    }
  },
  keyTest: (event) => event[KEYS.CTRL_OR_CMD] && event.key === KEYS.O,
});

export const actionSaveImageToStorage = register({
  name: "saveImageToStorage",
  viewMode: true,
  trackEvent: { category: "export" },
  perform: async (elements, appState, value, app) => {
    try {
      await saveAsImageToStorage(
        elements,
        {
          ...appState,
          fileHandle: null,
        },
        app.files,
      );

      return false;
    } catch (error: any) {
      if (error?.name !== "AbortError") {
        console.error(error);
      } else {
        console.warn(error);
      }
      return { commitToHistory: false };
    }
  },
  keyTest: (event) =>
    event.key === KEYS.S && event.shiftKey && event[KEYS.CTRL_OR_CMD],
  PanelComponent: ({ updateData }) => (
    <ToolButton
      type="button"
      icon={saveAs}
      title={t("buttons.save")}
      aria-label={t("buttons.save")}
      showAriaLabel={useDevice().isMobile}
      hidden={!nativeFileSystemSupported}
      onClick={() => updateData(null)}
      data-testid="save-as-button"
    />
  ),
});

export const actionSaveImageToStorageAsComment = register({
  name: "saveImageToStorage",
  viewMode: true,
  trackEvent: { category: "export" },
  perform: async (elements, appState, value, app) => {
    try {
      await saveAsImageToComment(
        elements,
        {
          ...appState,
          fileHandle: null,
        },
        app.files,
        temporarySceneStorage.getCommentParams(),
      );

      return false;
    } catch (error: any) {
      if (error?.name !== "AbortError") {
        console.error(error);
      } else {
        console.warn(error);
      }
      return { commitToHistory: false };
    }
  },
  keyTest: (event) =>
    event.key === KEYS.S && event.shiftKey && event[KEYS.CTRL_OR_CMD],
  PanelComponent: ({ updateData }) => (
    <ToolButton
      type="button"
      icon={saveAs}
      title={t("buttons.save")}
      aria-label={t("buttons.save")}
      showAriaLabel={useDevice().isMobile}
      hidden={!nativeFileSystemSupported}
      onClick={() => updateData(null)}
      data-testid="save-as-button"
    />
  ),
});
