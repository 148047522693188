import { Fragment } from "react";
import IconButton from "@mui/material/IconButton";
import { enqueueSnackbar, closeSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";

export const ShowNotification = (
  msg: string,
  variant: string,
  notHide?: boolean,
  url?: string,
  urlText?: string,
) => {
  if (msg) {
    const action = (key: string) => (
      <IconButton
        onClick={() => closeSnackbar(key)}
        aria-label="close"
        color="inherit"
        sx={{ p: 0.5 }}
      >
        <CloseIcon />
      </IconButton>
    );

    const opt: any = {
      variant,
      autoHideDuration: notHide ? null : 10000,
      preventDuplicate: true,
    };

    if (notHide) {
      opt.action = action;
    }

    const message = url ? (
      <Fragment>
        <label>
          {msg}{" "}
          <a href={url} target="_blank" rel="noreferrer">
            {urlText}
          </a>
        </label>
      </Fragment>
    ) : (
      msg
    );

    enqueueSnackbar(message, opt);
  }
};
