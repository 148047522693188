import React, { forwardRef } from "react";
import clsx from "clsx";

import "./FilledButton.scss";

export type ButtonVariant = "filled" | "outlined" | "icon";
export type ButtonColor = "primary" | "danger" | "warning" | "muted";
export type ButtonSize = "small" | "medium" | "large";

export type FilledButtonLinkProps = {
  label: string;
  url: string;
  children?: React.ReactNode;
  variant?: ButtonVariant;
  color?: ButtonColor;
  size?: ButtonSize;
  className?: string;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
};

export const FilledButtonLink = forwardRef<
  HTMLButtonElement,
  FilledButtonLinkProps
>(
  (
    {
      children,
      startIcon,
      label,
      url,
      variant = "filled",
      color = "special",
      size = "medium",
      fullWidth,
      className,
    },
    ref,
  ) => {
    return (
      <a
        className={clsx(
          "ExcButtonLink",
          `ExcButtonLink--color-${color}`,
          `ExcButtonLink--variant-${variant}`,
          `ExcButtonLink--size-${size}`,
          { "ExcButtonLink-fullWidth": fullWidth },
          className,
        )}
        id={"ExcButtonLink_a"}
        href={url}
        target="_blank"
        rel="noreferrer"
      >
        {startIcon && (
          <div className="ExcButtonLink__icon" aria-hidden>
            {startIcon}
          </div>
        )}
        {variant !== "icon" && (children ?? label)}
      </a>
    );
  },
);
