import { t } from "../i18n";
import { ALLOWED_IMAGE_MIME_TYPES } from "../classuperConstants";

const extractType = (blob: any, fileName: any) => {
  const types = blob.match(/(?=image)(.*?)(?=;base64)/);

  if (types?.length) {
    const type = types[0];
    const isSupportedImage = (
      ALLOWED_IMAGE_MIME_TYPES as readonly string[]
    ).includes(type);
    if (isSupportedImage) {
      return type;
    }
  }

  let ext = fileName.split(".").pop();
  if (ext === "jpg") {
    ext = "jpeg";
  }
  const type = `image/${ext}`;
  const isSupportedImage = (
    ALLOWED_IMAGE_MIME_TYPES as readonly string[]
  ).includes(type);

  if (isSupportedImage) {
    return type;
  }
  throw new Error(t("errors.unsupportedFileType"));
};

export function blobToFile(blob: any, fileName: any) {
  const type = extractType(blob, fileName);

  const arr = blob.split(",");

  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type });
}
