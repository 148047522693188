export const END_FREE_TIME_SHOW_MODAL =
  import.meta.env.VITE_END_FREE_TIME_SHOW_MODAL || 30; //time in second
export const FREE_TIME = import.meta.env.VITE_FREE_TIME || 30; //time in min

export const LIFETIME_TIMER_MODAL_KEY = "LTMK";
export const LIFETIME_TIMER_KEY = "LTK";
export const SCENE_LIFETIME =
  (import.meta.env.VITE_SCENE_LIFETIME || 1440) * 60 * 1000;

export const CLASSUPER_UPGRADE = import.meta.env.VITE_CLASSUPER_UPGRADE;
