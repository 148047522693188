import { MIME_TYPES } from "./constants";

export const boardEvents = {
  COLLABORATION_START: "board_collaboration_start",
  COLLABORATION_STOP: "board_collaboration_stop",
  SCENE_LOAD: "board_load_scene",
  SCENE_SAVE: "board_save_scene",
  RESPONSE_SCENE_LOAD: "board_response_scene_load",
  RESPONSE_IMAGE_LOAD: "board_response_image_load",
  LOAD_DOCUMENT_AFTER_START_BOARD: "board_load_document_after_start_board",
  LIBRARY_LOAD: "board_load_library",
  RESPONSE_LIBRARY_LOAD: "board_response_library_load",
  LIBRARY_SAVE: "board_save_library",
  IMAGE_SAVE_TO_ANSWER: "board_save_image_to_answer",
  GET_USER_INFO: "board_get_user_info",
  GET_USER_INFO_REQUEST: "board_get_user_info_request",
};

export const ALLOWED_IMAGE_MIME_TYPES = [
  MIME_TYPES.png,
  MIME_TYPES.jpg,
  MIME_TYPES.svg,
  MIME_TYPES.gif,
  MIME_TYPES.webp,
  MIME_TYPES.bmp,
  MIME_TYPES.ico,
] as const;
