import { atom } from "jotai";
import { appJotaiStore } from "../../../app-jotai";
import { inIframe } from "../../../../src/utils/inIframe";

export const isRedirectToClassuperAtom = atom<boolean>(false);

export const showRedirectToClassuperModal = () => {
  if (!inIframe()) {
    appJotaiStore.set(isRedirectToClassuperAtom, true);
    return true;
  }

  return false;
};
