import { getShortcutFromShortcutName } from "../../actions/shortcuts";
import { t } from "../../i18n";
import { useExcalidrawActionManager } from "../App";
import { ExportImageIcon, LoadIcon } from "../icons";
import DropdownMenuItem from "../dropdownMenu/DropdownMenuItem";

import { actionLoadSceneFromStorage } from "../../actions";

import "./DefaultItems.scss";
import { actionSaveImageToStorageAsComment } from "../../actions/actionClassuperExport";
import { temporarySceneStorage } from "../../utils/temporarySceneStorage";
import { inIframe } from "../../utils/inIframe";

export const LoadSceneFromStorage = () => {
  // eslint-disable-next-line
  const actionManager = useExcalidrawActionManager();

  if (
    !actionManager.isActionEnabled(actionLoadSceneFromStorage) ||
    !inIframe()
  ) {
    return null;
  }

  return (
    <DropdownMenuItem
      icon={LoadIcon}
      onSelect={() => actionManager.executeAction(actionLoadSceneFromStorage)}
      data-testid="load-button1"
      shortcut={getShortcutFromShortcutName("loadSceneFromStorage")}
      aria-label={t("buttons.loadFromStorage")}
    >
      {t("buttons.loadFromStorage")}
    </DropdownMenuItem>
  );
};
LoadSceneFromStorage.displayName = "LoadSceneFromStorage";

export const SaveAsAnswerCommentImage = () => {
  const actionManager = useExcalidrawActionManager();

  if (!actionManager.isActionEnabled(actionSaveImageToStorageAsComment)) {
    return null;
  }

  if (!inIframe() || !temporarySceneStorage.isAnswerDocument()) {
    return null;
  }

  // @ts-ignore
  return (
    <DropdownMenuItem
      icon={ExportImageIcon}
      data-testid="image-export-button"
      onSelect={() =>
        actionManager.executeAction(actionSaveImageToStorageAsComment)
      }
      aria-label={t("buttons.exportAnswerImage")}
    >
      {t("buttons.exportAnswerImage")}
    </DropdownMenuItem>
  );
};
SaveAsAnswerCommentImage.displayName = "SaveAsImage";
