import { NonDeletedExcalidrawElement } from "../element/types";
import { t } from "../i18n";
import { exportToCanvas } from "../scene/export";
import { AppState, BinaryFiles } from "../types";
import { canvasToBlob } from "../data/blob";

export const getSceneAsImage = async (
  elements: readonly NonDeletedExcalidrawElement[],
  appState: AppState,
  files: BinaryFiles,
) => {
  if (elements.length === 0) {
    throw new Error(t("alerts.cannotExportEmptyCanvas"));
  }
  const tempCanvas = await exportToCanvas(elements, appState, files, {
    exportBackground: appState.exportBackground,
    viewBackgroundColor: appState.viewBackgroundColor,
  });

  tempCanvas.style.display = "none";
  document.body.appendChild(tempCanvas);

  const blob = await canvasToBlob(tempCanvas);
  tempCanvas.remove();

  return blob;
};
