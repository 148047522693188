import { atom } from "jotai";
import { appJotaiStore } from "../app-jotai";
import {
  END_FREE_TIME_SHOW_MODAL,
  FREE_TIME,
  LIFETIME_TIMER_KEY,
  LIFETIME_TIMER_MODAL_KEY,
} from "./constants";
import { temporarySceneStorage } from "../../src/utils/temporarySceneStorage";
import { isCollaboratingAtom } from "../collab/Collab";
import { inIframe } from "../../src/utils/inIframe";

export const isEndFreeTimeAtom = atom(false);

class CollabTimer {
  private static instance?: CollabTimer;
  private readonly allocatedTime: number;
  private showModalTime: number;

  private idTimer: NodeJS.Timeout | null;

  private constructor(allocatedTime: number, showModalTime: number) {
    this.idTimer = null;
    this.allocatedTime = allocatedTime;
    this.showModalTime = showModalTime;
  }

  public static getInstance(allocatedTime: number, showModalTime: number) {
    if (!CollabTimer.instance) {
      CollabTimer.instance = new CollabTimer(allocatedTime, showModalTime);
    }
    return CollabTimer.instance;
  }

  getFreeTimePeriod = (timerMs: string) => {
    const ts = parseInt(timerMs) || 0;
    let timeout;
    const deltaMs = this.allocatedTime - (Date.now() - ts);

    if (deltaMs <= 0) {
      this.setCurrentTimerKey(true);
      timeout = this.allocatedTime;
    } else {
      timeout = deltaMs;
    }

    return timeout;
  };

  getShowModalPeriod = (timerMs: string, defPeriod: number | null = null) => {
    const ts = parseInt(timerMs) || 0;
    let timeout;
    const deltaMs = this.showModalTime - (Date.now() - ts);

    if (deltaMs <= 0) {
      this.setCurrentTimerKey(true);
      timeout = this.allocatedTime;
    } else {
      timeout = defPeriod || deltaMs;
    }

    return timeout;
  };

  getTimerPeriod() {
    const globalTimerTs: string | null =
      localStorage.getItem(LIFETIME_TIMER_KEY);

    const modalTimerTs: string | null = localStorage.getItem(
      LIFETIME_TIMER_MODAL_KEY,
    );

    if (globalTimerTs) {
      return this.getFreeTimePeriod(globalTimerTs);
    }

    if (modalTimerTs) {
      return this.getShowModalPeriod(modalTimerTs, 1);
    }

    this.setCurrentTimerKey(true);
    return this.allocatedTime;
  }

  private setLocalStorageKey(key: string) {
    localStorage.setItem(key, JSON.stringify(Date.now()));
  }

  private removeLocalStorageKey(key: string) {
    localStorage.removeItem(key);
  }

  removeStorageItems() {
    const items = [LIFETIME_TIMER_KEY, LIFETIME_TIMER_KEY];
    items.forEach((item) => this.removeLocalStorageKey(item));
  }

  private setCurrentTimerKey(global: boolean) {
    let keySet = LIFETIME_TIMER_KEY;
    let keyRm = LIFETIME_TIMER_MODAL_KEY;

    if (!global) {
      [keySet, keyRm] = [keyRm, keySet];
    }

    this.setLocalStorageKey(keySet);
    this.removeLocalStorageKey(keyRm);
  }

  isEnableTimer = () => {
    const isFreePlan = temporarySceneStorage.isFreePlan();
    const isCollaborating = appJotaiStore.get(isCollaboratingAtom);

    if (!inIframe() || !isFreePlan || !isCollaborating) {
      this.removeStorageItems();
      this.stop();
      return false;
    }
    return true;
  };

  start() {
    if (this.isEnableTimer()) {
      this.startTimer();
    }
  }

  reStart() {
    if (this.isEnableTimer()) {
      this.setCurrentTimerKey(true);
      this.startTimer();
    }
  }

  startTimer() {
    const periodOpenDialog = this.getTimerPeriod();
    this.stop();

    this.idTimer = setTimeout(() => {
      this.setCurrentTimerKey(false);
      const isFreePlan = temporarySceneStorage.isFreePlan();
      if (isFreePlan) {
        appJotaiStore.set(isEndFreeTimeAtom, true);
      }
    }, periodOpenDialog);
  }

  stop() {
    if (this.idTimer) {
      clearTimeout(this.idTimer);
      this.idTimer = null;
    }
  }
}

export const collabTimer = CollabTimer.getInstance(
  FREE_TIME * 60 * 1000,
  END_FREE_TIME_SHOW_MODAL * 1000,
);
