import { inIframe } from "./inIframe";
import { loadLibraryFromStorage } from "./loadLibraryFromStorage";
import { getLibraryItemsFromStorage as getLibraryItemsFromLocalStorage } from "../../excalidraw-app/data/localStorage";

export const getLibraryItemsFromStorage = () => {
  if (inIframe()) {
    return loadLibraryFromStorage();
  }

  return getLibraryItemsFromLocalStorage();
};
