import { AppState, BinaryFiles } from "../types";
import { ExcalidrawElement } from "../element/types";
import { atom } from "jotai";
import { appJotaiStore } from "../../excalidraw-app/app-jotai";

export type TemporaryScene = {
  appState?: AppState;
  elements?: ExcalidrawElement[];
  files?: BinaryFiles;
};

export type TemporarySceneParams = {
  assignmentId?: number;
  answerId?: number;
  fileName?: string;
};

export type User = {
  plan: string;
  role: string;
  userId: number;
};

export const userRoleAtom = atom("");

export const USER_ROLES = {
  teacher: "teacher",
  student: "student",
};

export const USER_PLAN = {
  free: "free",
};

export class TemporarySceneStorage {
  private static instance: TemporarySceneStorage;
  private _scene: TemporaryScene = {};
  private _params: TemporarySceneParams | undefined;

  private _user: User = {
    plan: USER_PLAN.free,
    role: USER_ROLES.student,
    userId: -1,
  };

  constructor() {
    if (typeof TemporarySceneStorage.instance === "object") {
      return TemporarySceneStorage.instance;
    }
    TemporarySceneStorage.instance = this;
    return this;
  }

  setScene(
    appState: AppState,
    elements: ExcalidrawElement[],
    files: BinaryFiles,
  ) {
    this._scene = {
      appState,
      elements,
      files,
    };
  }

  setParams(assignmentId: number, answerId: number, fileName: string) {
    this._params = {
      assignmentId,
      answerId,
      fileName,
    };
  }

  getScene(): TemporaryScene {
    return this._scene;
  }

  isAnswerDocument(): boolean {
    return !!this._params;
  }

  getCommentParams(): TemporarySceneParams | undefined {
    return this._params;
  }

  setUser(user: User) {
    this._user = user;
    appJotaiStore.set(userRoleAtom, this._user.role);
  }

  getUserParamByName(name: keyof User): User[keyof User] | null {
    return this._user?.[name];
  }

  isFreePlan(): boolean {
    return this._user.plan === USER_PLAN.free;
  }

  isTeacherRole(): boolean {
    return this._user.role === USER_ROLES.teacher;
  }
}

export const temporarySceneStorage = new TemporarySceneStorage();
